import React from 'react'
import { Box } from '../components/Box'
import { ArticleSection } from '../components/ArticleSection'
import { BigImageSection } from '../components/BigImageSection'
import { CollectionSection } from '../components/CollectionSection'
import { Columns, Column } from '../components/Columns'
import { HighLightSection } from '../components/HighLightSection'
import { SliderBanner } from '../components/SliderBanner'
import { Recent, Like, Compass } from '../icons'
import {
  Section,
  Post,
  Collection,
  SectionsResults,
  SectionsResult,
  PostRender,
  Promotion,
  PulseList,
  Pulse,
  Meme
} from '../types'
import { AppContainer, AppContainerProps } from '../components/AppContainer'
import { Author, CoAuthor, Size } from '../../../core/src/types/collection'
import useAd from './useAd'
import { sendPageView } from '../fetchers'
import { MemeSection } from '../components/MemeSection'
import PulseSection from '../components/Pulse/PulseSection'

const formatCoAuthors = (authors: CoAuthor[]): Author[] => {
  if (!authors?.length) return []

  return authors.map(author => ({
    id: author.ID,
    avatar: author.user_avatar,
    name: author.display_name,
    nicename: author.user_nicename
  }))
}

type PostImageType = 'carousel' | 'grid' | 'bigImage' | 'other'
const getPostImage = (p: Post, type: PostImageType) => {
  if (type === 'carousel') {
    return (
      p.featured_image?.sizes?.post_cover || p.featured_image?.sizes?.large || p.featured_image?.sizes?.featured_card
    )
  }

  if (type === 'bigImage') {
    return p.featured_image?.sizes?.featured_card || p.featured_image?.sizes?.medium_card
  }

  if (type === 'grid') {
    return (
      p.featured_image?.sizes?.trending_cover ||
      p.featured_image?.sizes?.medium ||
      p.featured_image?.sizes?.featured_aside
    )
  }

  return p.featured_image?.sizes?.medium
}

export function getPostRender(
  configs: {
    imageSize?: string
    imageType?: PostImageType
  } = {}
) {
  const { imageSize = '', imageType } = configs
  return (p: Post): PostRender => {
    const imageSizeSelect = p.featured_image?.sizes?.[imageSize] || getPostImage(p, imageType) || p.featured_image

    let image = imageSizeSelect?.source_url || ''

    if (imageType === 'carousel') {
      image = p.featured_image?.source_url
    }

    let coAuthors = []
    if (p?.acf?.co_authors?.length) {
      coAuthors = formatCoAuthors(p.acf.co_authors)
    }

    const industry = p?.taxonomies?.industry
    const topic = p?.taxonomies?.topic
    let categoryUrl = ''
    let category = ''
    if (topic && topic.length) {
      categoryUrl = `/topic/${p.taxonomies.topic?.[0]?.slug}`
      category = p.taxonomies.topic?.[0]?.name || ''
    }

    if (industry) {
      categoryUrl = `/industry/${industry?.[0]?.slug}`
      category = industry?.[0]?.name || ''
    }

    return {
      authorUrl: '',
      coAuthors,
      author: p.author,
      date: p.date,
      imageHeight: (imageSizeSelect as Size)?.height || 0,
      imageWidth: (imageSizeSelect as Size)?.width || 0,
      caption: p.featured_image?.caption,
      id: p.id,
      slug: p.slug,
      title: p.acf?.short_title || p.title.rendered,
      categoryUrl,
      url: `/${p.slug}`,
      category,
      image,
      excerpt: p.excerpt?.rendered
    }
  }
}

export function RenderCarouselSection(data: {
  carousel: Section
  list?: Section | Post[] | Collection | PulseList
  seeAllList: boolean
  promotions?: {
    [key: string]: Promotion[]
  }
}) {
  const { carousel, list, promotions } = data

  const listCarouselData = carousel.posts.map(
    getPostRender({
      imageType: 'carousel'
    })
  )
  let listPosts: Post[] | Pulse[] | PostRender[] | Meme[] = []
  let listSeeAllUrl
  let listTitle
  let listIcon

  if (list) {
    if ((list as Section).posts) {
      listPosts = (list as Section).posts.slice(0, 10)
    } else if ((list as PulseList).list) {
      listPosts = (list as PulseList).list.slice(0, 10)
      listSeeAllUrl = '/pulses'
      listTitle = '快讯'
    } else {
      listPosts = (list as Post[]).map(getPostRender()).slice(0, 3)
      listSeeAllUrl = '/recent'
      listTitle = '最新文章'
    }
  }

  const adLatestTop = useAd({
    key: 'latest-top',
    promotions,
    onSendView: sendPageView
  })

  return (
    <AppContainer>
      <Box
        display={{ lg: 'table' }}
        style={{
          width: '100%',
          tableLayout: 'fixed'
        }}
      >
        <Box display={{ lg: 'table-cell' }} width={{ lg: '65%' }} pr={{ lg: '35px' }}>
          <SliderBanner list={listCarouselData} />
        </Box>
        <Box
          verticalAlign='top'
          display={{ lg: 'table-cell' }}
          width={{ lg: '35%' }}
          borderLeft={{
            base: 'none',
            lg: '1px solid'
          }}
          borderColor={{
            base: 'none',
            lg: 'border'
          }}
          pl={{
            base: '0',
            lg: '35px'
          }}
        >
          {adLatestTop.autoRender}

          {list && Array.isArray(list) ? (
            <ArticleSection
              seeAllUrl={listSeeAllUrl}
              list={listPosts as PostRender[]}
              title={listTitle}
              iconImage={listIcon}
              {...((list as Post[]).length && { icon: <Recent /> })}
            />
          ) : (
            <React.Fragment>
              {list && typeof list === 'object' && list['posts'] ? (
                <MemeSection icon={<Compass />} title={'36氪出海·领读'} list={listPosts as Meme[]} />
              ) : (
                <PulseSection title={'出海快讯'} list={listPosts as Pulse[]} />
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </AppContainer>
  )
}

function renderFeatureSection(section: Section) {
  const listPosts = section.posts.map(getPostRender({ imageType: 'carousel' }))
  return <HighLightSection title={section.title.rendered} icon={section.section_icon} list={listPosts} />
}

export function renderRowColumns(data: {
  start: React.ReactNode
  end?: React.ReactNode
  startSize: string
  containerProps?: AppContainerProps
}) {
  const { startSize, start, end, containerProps } = data
  return (
    <AppContainer
      pt={{
        base: '60px',
        md: '80px'
      }}
      {...containerProps}
    >
      <Columns collapse='lg' gutter='0px'>
        <Column fWidth={startSize} width='100%'>
          <Box
            width='100%'
            paddingRight={{
              base: 0,
              lg: 50
            }}
          >
            {start}
          </Box>
        </Column>
        {end && (
          <Column>
            <Box
              paddingTop={{
                base: '40px',
                lg: '0'
              }}
              borderLeft={{
                base: 'none',
                lg: '1px solid'
              }}
              borderColor={{
                base: 'none',
                lg: 'border'
              }}
              // pl={{
              //   base: '0',
              //   lg: '60px'
              // }}
            >
              {end}
            </Box>
          </Column>
        )}
      </Columns>
    </AppContainer>
  )
}

export function renderRows(
  data: SectionsResults,
  promotions?: {
    [key: string]: Promotion[]
  },
  config: { containerProps?: AppContainerProps } = {}
) {
  const rows: Array<React.ReactNode> = []
  const { containerProps } = config
  let lastUsed = -1
  data.forEach((d, index) => {
    if (index === lastUsed) {
      return
    }

    const next: undefined | SectionsResult = data[index + 1]

    if (d.section.layout === 'Carousel') {
      let list
      if (next?.section?.layout === 'List') {
        list = next.data as Section
      }

      if (next?.section?.layout === 'Most Recent') {
        list = next.data as Post[]
      }

      if (next?.section?.layout === 'Memes') {
        list = next.data as any
      }

      if (next?.section?.layout === 'pulse') {
        list = next.data as PulseList
      }

      rows.push(
        RenderCarouselSection({
          carousel: d.data as Section,
          list,
          seeAllList: next?.section?.viewAll,
          promotions
        })
      )
      lastUsed = next && (next.section.layout === 'List' || next.section.layout === 'Most Recent') ? index + 1 : index

      return
    }

    if (d.section.layout === 'Featured') {
      rows.push(renderFeatureSection(d.data as Section))
      lastUsed = index
      return
    }

    if (d.section.layout === 'Most Recent') {
      const posts: Post[] = d.data as Post[]
      if (index === 1) {
        const startSection = (
          <ArticleSection
            seeAllUrl='/recent'
            icon={<Recent />}
            list={posts.slice(0, 3).map(getPostRender())}
            title='最新文章'
          />
        )

        let endSection: JSX.Element | string = ''

        if (next && next.section.layout === 'Grid') {
          const section = next.data as Section
          const listPosts = section.posts
            .map(
              getPostRender({
                imageType: 'grid'
              })
            )
            .slice(0, 4)
          lastUsed = index + 1
          endSection = (
            <Box
              pl={{
                base: '0',
                lg: '60px'
              }}
            >
              <ArticleSection
                displayStyle='grid'
                iconImage={section.section_icon}
                list={listPosts}
                title={section.title.rendered}
              />
            </Box>
          )
        } else {
          lastUsed = index
        }

        rows.push(
          renderRowColumns({
            containerProps,
            startSize: '35%',
            start: startSection,
            end: endSection
          })
        )
      } else {
        const listPosts = posts
          .map(
            getPostRender({
              imageSize: 'trending_cover'
            })
          )
          .slice(0, 10)

        rows.push(
          <CollectionSection
            slug={'recent'}
            seeAllUrl={`/recent`}
            list={listPosts}
            title={'最新文章'}
            iconImage={<Recent />}
            key={'latest'}
          />
        )
      }

      return
    }

    if (d.section.layout === 'Related Post') {
      const posts: Post[] = d.data as Post[]

      const startSection = <ArticleSection icon={<Like />} list={posts.map(getPostRender())} title={'推荐阅读'} />

      let endSection: JSX.Element | string = ''

      if (next && next.section.layout === 'Grid') {
        const section = next.data as Section
        const listPosts = section.posts
          .map(
            getPostRender({
              imageType: 'grid'
            })
          )
          .slice(0, 4)
        lastUsed = index + 1
        endSection = (
          <ArticleSection
            displayStyle='grid'
            iconImage={section.section_icon}
            list={listPosts}
            title={section.title.rendered}
            pl={{
              base: '0',
              lg: '60px'
            }}
          />
        )
      } else {
        lastUsed = index
      }

      rows.push(
        renderRowColumns({
          containerProps,
          startSize: '35%',
          start: startSection,
          end: endSection
        })
      )

      return
    }

    if (d.section.layout === 'Big Image') {
      const data = d.data as Section
      lastUsed = index
      const list = data.posts.map(getPostRender({ imageType: 'bigImage' }))
      rows.push(
        <BigImageSection
          seeAllUrl={`/sections/${data.slug}`}
          title={data.title.rendered}
          titleIcon={data.section_icon}
          list={list}
        />
      )
      return
    }

    if (d.section.layout === 'List') {
      const section = d.data as Section
      const listPosts = section.posts.map(
        getPostRender({
          imageSize: 'trending_cover'
        })
      )

      const startSection = (
        <ArticleSection
          seeAllUrl={d.section.viewAll && `/sections/${section.slug}`}
          iconImage={section.section_icon}
          list={listPosts}
          title={section.title.rendered}
        />
      )

      let endSection: JSX.Element | string = ''

      if (next && next.section.layout === 'Grid') {
        const section = next.data as Section
        const listPosts = section.posts
          .map(
            getPostRender({
              imageType: 'grid'
            })
          )
          .slice(0, 4)
        lastUsed = index + 1
        endSection = (
          <Box
            pl={{
              base: '0',
              lg: '60px'
            }}
          >
            <ArticleSection
              displayStyle='grid'
              seeAllUrl={next.section.viewAll && `/sections/${section.slug}`}
              iconImage={section.section_icon}
              list={listPosts}
              title={section.title.rendered}
            />
          </Box>
        )
      } else {
        lastUsed = index
      }
      rows.push(
        renderRowColumns({
          containerProps,
          startSize: '35%',
          start: startSection,
          end: endSection
        })
      )
    }

    if (d.section.layout === 'Grid') {
      const section = d.data as Section
      const listPosts = section.posts
        .map(
          getPostRender({
            imageType: 'grid'
          })
        )
        .slice(0, 4)

      const startSection = (
        <ArticleSection
          displayStyle='grid'
          iconImage={section.section_icon}
          list={listPosts}
          title={section.title.rendered}
        />
      )

      let endSection: JSX.Element | string = ''

      if (next && next.section.layout === 'List') {
        const section = next.data as Section
        const listPosts = section.posts.map(
          getPostRender({
            imageSize: 'trending_cover'
          })
        )
        lastUsed = index + 1
        endSection = (
          <Box
            pl={{
              base: '0',
              lg: '40px'
            }}
          >
            <ArticleSection
              seeAllUrl={next.section.viewAll && `/sections/${section.slug}`}
              iconImage={section.section_icon}
              list={listPosts}
              title={section.title.rendered}
            />
          </Box>
        )
      } else {
        lastUsed = index
      }
      rows.push(
        renderRowColumns({
          containerProps,
          startSize: '65%',
          start: startSection,
          end: endSection
        })
      )
    }

    if (d.section.layout === 'Collection') {
      const section = d.data as Collection
      const listPosts = section.posts
        .map(
          getPostRender({
            imageSize: 'trending_cover'
          })
        )
        .slice(0, 10)
      rows.push(
        <CollectionSection
          slug={section.slug}
          seeAllUrl={`/collections/${section.slug}`}
          list={listPosts}
          title={section.title.rendered}
          iconImage={section.icon?.sizes?.thumbnail}
          key={section.id}
        />
      )
    }
  })

  return rows
}
